// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  padding: 50px;

  @media (max-width: 805px) {
    padding: 15px;
  }
`;

const Header = styled.div`
  font-size: 1.2em;
  font-weight: 800;
  margin: 10px 0px 40px 0px;
  text-align: center;
`;

const Text = styled.div`
  font-size: 0.7em;
  margin: 10px 0px 30px 0px;
`;

const SectionHeading = styled.div`
  font-size: 0.9em;
  font-weight: 800;
`;

const Paragraph = styled.p`
  font-size: 1em;
`;

function PrivacyPolicy() {
  return (
    <Container>
      <Header>PRIVACY POLICY</Header>
      <Text>
        ZARENA AD (“ZARENA” or “we”) adopted this privacy policy (“Privacy Policy”) to reflect our commitment to
        protecting your privacy. We take the collection of personal information from our End-Users seriously and we are
        committed to protecting each User’s privacy in accordance with Regulation (EU) 2016/679 of the European
        Parliament and of the Council of 27 April 2016, on the protection of natural persons with regard to the
        processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (“GDPR”) and
        this Privacy Policy. The Privacy Policy is part of the Terms and Conditions (the “Terms”) which are available on
        our website https://www.cindyapp.com/en/terms.html (the “Site”) and on our mobile application “CindyApp” (the
        “App”) (collectively the Site and the App “CindyApp”). All capitalized terms have the meaning given to them in
        the Terms, unless otherwise stated herein.
      </Text>
      <SectionHeading>PERSONAL DATA</SectionHeading>
      <SectionHeading>What is Personal Data</SectionHeading>
      <Text>
        Personal Data is any information that relates to an identified or an identifiable living individual. Different
        pieces of information, which collected together can lead to the identification of a particular person, also
        constitute personal data.
      </Text>
      <SectionHeading>Personal Data We May Collect</SectionHeading>
      <Text>
        Information that you provide to us We collect the following information that you provide us through
        <ul>
          <li>
            i. registration on our App:
            <ul>
              <li>First and last name</li>
              <li>E-mail address</li>
              <li>Picture (optional)</li>
              <li>Phone number</li>
            </ul>
          </li>
          <li>
            ii. the contact form on our Site/App:
            <ul>
              <li>E-mail address</li>
              <li> Other information that you may decide to provide Us when contacting us.</li>
            </ul>
          </li>
        </ul>
      </Text>
      <SectionHeading>Information that we collect from third parties </SectionHeading>
      <Text>
        We may receive information about you from other sources. We can add this information to the information we have
        already collected from you through our services to improve them. Links to the privacy policies of third-party
        service providers used by the App:
        <ul>
          <li>Google Play Services</li>
          <li>Firebase Analytics</li>
          <li>Mixpanel</li>
          <li>HubSpot</li>
          <li>Hotjar</li>
          <li>Crashlyrics</li>
          <li>Google Analytics</li>
          <li>Facebook SDK</li>
        </ul>
      </Text>
      <SectionHeading>FOR WHAT PURPOSES WE USE YOUR PERSONAL DATA AND ON WHAT GROUNDS</SectionHeading>
      <Text>
        To the extent permitted by applicable law, we may use Personal Data:
        <ul>
          <li>
            To provide CindyApp’s services to your benefit
            <ul>
              <li>creating and managing a profile on the App and the Site;</li>
              <li>
                processing of reservations, including, but not limited to, acceptance, validation, sending reminders of
                appointments, sending your personal data (names, email, phone number, photo, if available) to the venues
                where you made reservations for;
              </li>
              <li>
                resolving issues related to cancellation of reservations or any other issues related to reservations or
                other services, provided by CindyApp;
              </li>
              <li>
                providing support, including answering your questions about your reservations and the services on the
                Site and the App.
              </li>
            </ul>
          </li>
        </ul>
        <Paragraph>
          The processing of your data for these purposes is in most cases necessary for the conclusion and performance
          of a contract between ZARENA and you. Additionally, the implementation of these purposes requires processing
          under applicable law, including tax and accounting legislation.
        </Paragraph>
        <ul>
          <li>To respond to your inquiries</li>
          <li>To improve our services </li>
          <Paragraph>
            We would always like to offer you the best experience using CindyApp’s services. For this purpose, we can
            use certain information about your user behaviour, invite you to fill in satisfaction surveys after
            completing a registration, or conduct directly, or with partners, market research, and other research, and
            send you educational emails. Educational emails contain instructions, tips or, perhaps, information about
            the advanced features of CindyApp. The purpose of these communications is to streamline end user work in the
            App. Educational emails never include marketing messages.
          </Paragraph>
          <Paragraph>
            We base these activities on ourlegitimate business interests by always ensuring that your fundamental rights
            and freedoms are unaffected.
          </Paragraph>
          <li>For direct marketing</li>
          <Paragraph>
            We require your prior consent to send you different marketing messages, e. g. newsletters, surveys. You can
            change your mind and withdraw your consent at any time by:
          </Paragraph>
          <ul>
            <li>Using the "Unsubscribe" link in the messages you receive from us;</li>
            <li>By contacting us using the contact details below.</li>
          </ul>
        </ul>
        <ul>
          <li>Participation in competitions and different promotions</li>
          <Paragraph>
            We might organize different competitions and promotions, e.g. Instagram Giveaways. If you decide to
            participate in such an event, then we will process your personal data for conducting it. If you don’t want
            your personal data to be processed for such reasons, you should not participate in such events.
          </Paragraph>
          <li>Protecting our legitimate interests</li>
          <Paragraph>
            There may be cases in which we use or transmit information to protect our rights and our commercial
            activities. These may include:
          </Paragraph>
          <ul>
            <li>measures to protect the Site/App and the users of the Site/App against cyber-attacks;</li>
            <li>
              measures to prevent and detect attempts at fraud, including the transmission of information to competent
              public authorities;
            </li>
            <li>measures to manage various other risks.</li>
          </ul>
        </ul>
        <Paragraph>
          The main reason for these types of processing is our legitimate interest in protecting our business by making
          sure that we ensure that all the measures we undertake ensure a balance between our interests and your
          fundamental rights and freedoms.
        </Paragraph>
        <Paragraph>
          In addition, in some cases, our processing is based on legal provisions such as the obligation to protect the
          goods and the values provided by the applicable legislation in this respect.
        </Paragraph>
        <Paragraph>
          On our Site/App we can process information using cookies and similar technologies under the Cookie Policy
          below.
        </Paragraph>
      </Text>
      <SectionHeading>FOR HOW LONG WE STORE YOUR PERSONAL DATA </SectionHeading>
      <Text>
        As a rule, we store your Personal Data while you have an account on the App/Site. You may always ask Us to
        delete certain information or to close your account and we will respond to this request by retaining certain
        information, even after the account is closed when the applicable law or legitimate interests impose it.
        Personal information, collected for contact purposes only is stored until completion of the communication,
        unless otherwise required by law.
      </Text>
      <SectionHeading>SENSITIVE INFORMATION </SectionHeading>
      <Text>
        We ask that you not send us, and you not disclose, any sensitive Personal Data (e.g., information related to
        racial or ethnic origin, political opinions, religion or other beliefs, health or medical condition, criminal
        background or trade union membership) on or through the Site and the App or otherwise to us.
      </Text>
      <SectionHeading>HOW PERSONAL DATA MAY BE DISCLOSED</SectionHeading>
      <Text>
        To the extent permitted by applicable law, your Personal Data may be disclosed:
        <ul>
          <li>to the service provides you have chosen to make reservation with;</li>
          <li>
            to our third-party service providers who provide services such as accounting, website hosting, data
            analysis, infrastructure provision, IT services, email delivery services and other services, to enable them
            to provide services;{' '}
          </li>
          <li>
            to a third party in the event of any reorganization, merger, sale, joint venture, assignment, transfer or
            other disposition of all or any portion of our business, assets or stock (including in connection with any
            bankruptcy or similar proceedings).
          </li>
          <li>
            as we believe to be necessary or appropriate: (a) under applicable law, including laws outside your country
            of residence; (b) to comply with legal process; (c) to respond to requests from public and government
            authorities, including public and government authorities outside your country of residence; (d) to enforce
            our terms and conditions; (e) to protect our operations or those of any of our affiliates; (f) to protect
            our rights, privacy, safety or property, and/or that of our affiliates, you or others; and (g) to allow us
            to pursue available remedies or limit the damages that we may sustain.
          </li>
        </ul>
      </Text>
      <SectionHeading>USER’S RIGHTS</SectionHeading>
      <SectionHeading>Access to your Personal Data</SectionHeading>
      <Text>
        You have the right to view, amend, or delete the Personal Data that we hold about you. To request access please
        email support@cindyapp.com. We will provide the information free of charge and within one month, except in the
        event that the request is unfounded, excessive or repetitive, in which case we reserve the right to charge a
        proportionate administration fee or refuse.
      </Text>
      <SectionHeading>Rectification of Personal Data</SectionHeading>
      <Text>
        If any of the information we hold on you is inaccurate or incomplete, you may ask us to correct or complete it
        at any time.
      </Text>
      <SectionHeading>Your right of erasure, or to be forgotten</SectionHeading>
      <Text>
        You may ask us to delete your Personal Data, but only if:
        <ul>
          <li>it is no longer necessary for the purposes for which it was collected; or</li>
          <li>you have withdrawn your consent (if the processing of the data is based solely on consent); or</li>
          <li>exercise a legitimate right of objection; or</li>
          <li>it has been unlawfully processed; or</li>
          <li>there is a legal obligation in this respect. </li>
        </ul>
        <Paragraph>
          We have no obligation to honor your request for deletion of your Personal Data if processing is required:
        </Paragraph>
        <ul>
          <li>to fulfill a legal obligation; or</li>
          <li>to establish, exercise or protect a legal claim.</li>
        </ul>
        There are certain other circumstances in which we are not obliged to comply with your request for data deletion,
        even though these are the most likely circumstances in which we may decline your claim. To request deletion of
        your personal information, contact support@cindyapp.com using the email address we hold for you or otherwise
        proving your identity. Please be aware that the process of deleting data and deleting the Site with all data and
        documents associated with it is an irreversible process.
      </Text>
      <SectionHeading>Restrict data processing</SectionHeading>
      <Text>
        You may ask us to restrict the processing of your Personal Data, but only if:
        <ul>
          <li>
            its accuracy is being challenged (see Rectification of Personal Data section) to be able to verify its
            accuracy; or
          </li>
          <li>processing is illegal, but you do not want the data to be deleted; or</li>
          <li>
            it is no longer necessary for the purposes for which it was collected, but we still need to identify,
            exercise or defend a legal claim; or
          </li>
          <li>
            you have already exercised the right of objection and you are checking whether the dominance of our rights
            is still in place. We may continue to use your Personal Data as a result of a restriction request:
          </li>
          <li>if we have your consent; or </li>

          <li>to establish, exercise or defend a legal claim; or </li>
          <li>to protect the rights of the Site Owner or any other person or entity.</li>
        </ul>
      </Text>
      <SectionHeading>Data portability</SectionHeading>
      <Text>
        You may ask us to provide your Personal Data in a structured, widely used and machine-readable format, or you
        may request that it is directly transferred to another data operator, but only if:
        <ul>
          <li>the processing is based on your agreement or the conclusion of a contract with you; and </li>
          <li>processing is carried out automatically.</li>
        </ul>
      </Text>
      <SectionHeading>Right of objection</SectionHeading>
      <Text>
        You may object at any time, for reasons related to your particular situation, to the processing of your Personal
        Data based on our legitimate interests if you believe that your fundamental rights and freedoms dominate these
        interests. You may also object at any time to processing your data for direct marketing purposes by following
        the instructions on the email we send you or by sending an email to support@cindyapp.com.
      </Text>
      <SectionHeading>Making automated decisions</SectionHeading>
      <Text>
        ZARENA does not conduct profiling or automated individual decision-making. You can ask us not to be the subject
        of a decision based solely on automatic processing, but only when that decision:
        <ul>
          <li>has legal consequences for you; or</li>
          <li>affects you in a similar way and to a great extent.</li>
        </ul>
        This right is inapplicable if the decision taken after the automatic decision has been taken:
        <ul>
          <li>is needed for execution and fulfillment of a contract with you;</li>
          <li> is permitted by law and there are adequate safeguards for your rights and freedoms; or</li>
          <li>is based on your explicit consent.</li>
        </ul>
      </Text>
      <SectionHeading>Your right to file a complaint with the Supervisory Body</SectionHeading>
      <Text>
        <p>
          If you believe that ZARENA processes your personal data in the wrong way, you can contact
          support@cindyapp.com.
        </p>
        <p>You have the right to complain to the local authority on the processing of your Personal Data.</p>
        <p>
          In Bulgaria, the contact details of the supervisory authority for the protection of Personal Data are as
          follows:
        </p>
        <p>Commission for Personal Data Protection</p>
        <p>2 Prof. Tsvetan Lazarov Blvd., Sofia 1592, </p>
        <p>Phone Number: + 359 2 915 35 18 </p>
        <p>E-mail: kzld@cpdp.bg </p>
      </Text>
      <SectionHeading>OTHER INFORMATION</SectionHeading>
      <SectionHeading>Other Information We May Collect</SectionHeading>
      <Text>
        “Other Information” is any information that does not reveal your specific identity, such as:
        <ul>
          <li>browser and device information, carrier, internet connectivity</li>
          <li>server log file information and application usage logs</li>
          <li> information collected through cookies, pixel tags and other technologies</li>
          <li>demographic information and other information provided by you</li>
          <li>location information</li>
        </ul>
        We may use and disclose Other Information for any purpose, except where we are required to do otherwise under
        applicable law. If we are required to treat Other Information as Personal Data under applicable law, then we may
        use and disclose Other Information for all the purposes for which we use and disclose Personal Information.
      </Text>
      <SectionHeading>DATA SECURITY METHODS</SectionHeading>
      <Text>
        For the purpose of securing the User’s data against their unauthorized or accidental disclosure, we utilize
        reasonable and appropriate technical and organizational measures. Technical measures consist in the application
        of technologies that prevent unauthorized access to the User’s data by third parties. For the purpose of maximum
        protection, we use encryption of the User’s and end users’ data, primarily including passwords for logging into
        the CindyApp system, communication within the CindyApp system and all data stored on servers. Organizational
        measures comprise a set of rules of behavior for our employees and are incorporated into CindyApp’s internal
        regulations, which are, however, considered confidential due to security reasons. CindyApp takes to ensure that,
        in the event of the placement of servers at a data center operated by a third party, similar technical and
        organizational measures are also implemented by such third party.
        <Paragraph>
          All data are placed only on servers located within the European Union or in countries ensuring personal data
          protection in a manner equivalent to the protection ensured by the legal regulations of the Republic of
          Ireland.
        </Paragraph>
      </Text>
      <SectionHeading>TRANSFER OF PERSONAL DATA TO A THIRD COUNTRY OR INTERNATIONAL ORGANIZATION</SectionHeading>
      <Text>
        ZARENA does not transfer personal data to third countries, outside the EU, unless to companies that have
        undertaken to comply with the principles of personal data protection imposed through a Privacy Shield. Such
        companies operate only in the position of personal data processors.
      </Text>
      <SectionHeading>THIRD PARTY SITES</SectionHeading>
      <Text>
        The App and the Site may contain links to other websites, mobile applications, and other online services
        operated by third parties. This Privacy Policy does not address, and we are not responsible for, the privacy,
        information or other practices of any third parties, including any third party operating any site to which these
        Sites contain a link. The inclusion of a link on thе Site/App does not imply endorsement of the linked site by
        us.
        <Paragraph>
          We are also not responsible for the collection, usage and disclosure policies and practices (including the
          data security practices) of other organizations, such as Facebook, Apple, Google, Microsoft, RIM or any other
          app developer, app provider, social media platform provider, operating system provider, wireless service
          provider or device manufacturer, including in connection with any Personal Data you disclose to other
          organizations through or in connection with our social media pages.
        </Paragraph>
      </Text>
      <SectionHeading>COOKIES POLICY</SectionHeading>
      <SectionHeading>What are cookies?</SectionHeading>
      <Text>
        Cookies are small text files that are stored on your computer or mobile device. They are widely used in order to
        make websites work, or work in a better, more efficient way. They can do this because websites can read and
        write these files, enabling them to recognize you and remember important information that will make your use of
        a website more convenient (for example by remembering preference settings).
        <Paragraph>
          The Site automatically identifies the user’s IP address. An IP address is a number automatically assigned to
          the user’s computer after connecting to the internet. All such information is recorded in an activity file by
          the server, which enables the subsequent processing of data.
        </Paragraph>
      </Text>
      <SectionHeading>Why do we use cookies?</SectionHeading>
      <Text>
        Cookie files and similar technologies serve several purposes, which include:
        <ul>
          <li>
            Log-in and verification: As soon as the User uses a personal account in order to log on, an encrypted cookie
            file is stored on the User’s device, which enables the User to move between the pages of the website without
            the need to repeatedly log on. The User can also save their log-in information so that they do not need to
            log in every time they return to the Site.
          </li>
          <li>
            Editing: CindyApp uses cookie files in order to adjust the content and information to the requirements of
            Users in order to ensure the user-friendliness of the website.
          </li>
          <li>
            Marketing: CindyApp uses cookie files in order to monitor its advertising campaigns, and for the monitoring
            of User submissions, applications and discount coupons, promotions and contests.
          </li>
          <li>
            Diagnostics: CindyApp uses cookie files for the purpose of diagnosing and repairing technical problems
            reported by Users or programmers that are associated with the IP addresses under the control of a specific
            web company or connection provider.
          </li>
          <li>
            Analysis: CindyApp uses cookie files and other identifiers for the purpose of collecting data on the use and
            performance of the website www.cindyapp.com.
          </li>
        </ul>
        <Paragraph>
          There may also be third party cookie files located on the Site. This may be, for example, because we have
          authorized a third party to conduct a website analysis. CindyApp utilizes the following service providers:
        </Paragraph>
        <ul>
          <li>
            Google Inc. (googleapis.com, google-analytics.com, google.com, google.bg, googleadservices.com,
            googletagmanager.com)
          </li>
          <li>Mixpanel Inc. (mixpanel.com)</li>
          <li>Facebook, Inc. (facebook.com, analytics.facebook.com, developers.facebook.com)</li>
        </ul>
      </Text>
      <SectionHeading>Cookie Settings</SectionHeading>
      <Text>
        <Paragraph>
          The majority of web browsers accept cookie files automatically. However, it is possible to utilize the
          controls that enable them to be blocked or removed. Users of the Site are thus entitled to set their browser
          in such a way so that the use of cookies on their computer is prevented.
        </Paragraph>
        <Paragraph>
          We utilize two types of cookie files – permanent and one-time. A permanent cookie file remains on the hard
          disk even after the browser is closed. Permanent cookie files may be used by the browser during subsequent
          visits to the website www.cindyapp.com. Permanent cookie files may be removed. One-time cookie files are
          temporary and are erased as soon as you close the browser.
        </Paragraph>
        <Paragraph>
          Instructions for blocking or removing cookie files in browsers can generally be found in the personal data
          protection principles or in the user guide documentation of individual browsers.
        </Paragraph>
      </Text>
      <SectionHeading>Protocol Files</SectionHeading>
      <Text>
        The User’s browser automatically reports certain information upon every display of the website www.cindyapp.com.
        When registering on the website www.cindyapp.com or when browsing the website, servers automatically record
        certain information that the web browser sends upon every visit to the website. These server protocols
        (so-called “log files”) may contain information such as a web request, IP address, type of internet browser,
        browser language, linking / exit sites and URLs, platform type, number of clicks, domain names, entry portals,
        number of pages seen and the order of such pages, the amount of time spent on certain pages, the date and time
        of a submitted
      </Text>
      <SectionHeading>DATA CONTROLLER</SectionHeading>
      <Text>
        The data controller for the information you provide or that we collect pursuant to this Privacy Policy is:
        ZARENA AD, UIC 120008977, with seat and management address at: Isaka 66A, Markovo 4108, Bulgaria.
      </Text>
      <SectionHeading>HOW TO CONTACT</SectionHeading>
      <Text>
        <Paragraph>
          US You may contact ZARENA directly if you have any question regarding our privacy practices. Please send your
          query to support@cindyapp.com. CHANGES TO THIS PRIVACY POLICY We may modify this Privacy Policy at any time.
          The current version of the Privacy Policy is always be available on the Site and the App. If a significant
          change occurs within this policy in regard to the manners of handling personal data, ZARENA will inform you by
          publishing a notification in a visible manner prior to the implementation of such changes. We agree that
          changes cannot be retroactive. We recommend checking the Privacy policy from time to time when utilizing the
          CindyApp system.
        </Paragraph>
        <Paragraph>
          Your continued use of the Site and the App after the changes of the Privacy Policy have been announced to you,
          means that you are consenting to the updated terms.
        </Paragraph>
        <Paragraph>Last Updated: 04.01.2021</Paragraph>
      </Text>
    </Container>
  );
}

export default PrivacyPolicy;
